import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { images } from 'utils/images';
import { useRouter } from 'apis/history';
import { blue, text50, white } from 'styles/colors';
import {
  Caption,
  Container,
  FlexWrapper,
  H1,
  H2,
  Header,
  Image,
  OutlineButton,
  Seo,
  Small,
  Svg,
  TextWrapper,
} from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

const Contacts: React.FC = React.memo(() => {
  const { goToFaq } = useRouter();
  const { isMobile } = useQuery();

  return (
    <>
      <Seo title="Contacts | Pulsio" />
      <Container>
        <Header />
        <Svg
          src="icons/leaves_2"
          width="52"
          height="36"
          viewBox="0 0 52 36"
          fill="none"
          style={{ display: 'block', margin: '0 auto' }}
        />
        <TextWrapper maxWidth="33.5rem" margin="0 auto 3rem">
          <FlexWrapper
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            <H1>How can</H1>
            <Image
              src="man_smiling"
              width={isMobile ? '3.125rem' : '4.625rem'}
              margin="0 1.25rem"
            />
            <H1>we</H1>
            <H1 textAlign="center">&nbsp;help you?</H1>
          </FlexWrapper>
        </TextWrapper>
        <Caption textAlign="center">
          <a
            href="mailto:hi@pulsio.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            hi@pulsio.me
          </a>
        </Caption>
        <Small
          color={text50}
          textAlign="center"
          margin={isMobile ? '2rem auto 3.75rem' : '2rem auto 7.875rem'}
          lineHeight="1.625rem"
        >
          MAX NUTRITION, LLC
          <br />
          Gedimino ave. 1A-13, LT-01103 Vilnius
          <br />
          Registration code 305420438
          <br />
          VAT LT100013639018
        </Small>
        <StyledContainer>
          {isMobile ? null : (
            <LinesImage
              src="images/home/lines"
              width="1218"
              height="527"
              viewBox="0 0 1218 527"
              fill="none"
            />
          )}
          <Wrapper>
            <TextWrapper maxWidth="37.125rem" margin="0 auto 2.5rem">
              <H2 color={white} textAlign="center" mobileFontSize="2.125rem">
                You can also browse our FAQs to see if we’ve already answered
                your question.
              </H2>
            </TextWrapper>
            <OutlineButton
              maxWidth={isMobile ? '100%' : '9.5rem'}
              margin="0 auto"
              className="inverted"
              onClick={goToFaq}
            >
              FAQ
            </OutlineButton>
          </Wrapper>
        </StyledContainer>
      </Container>
    </>
  );
});

Contacts.displayName = 'Contacts';

export default Contacts;

const StyledContainer = styled(Container)`
  background-image: url(${images.lines});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  padding: 4.875rem 0 5.625rem;
  margin-bottom: 7.5rem;
  border-radius: 0.625rem;
  background: ${blue};

  @media ${mobile} {
    margin-bottom: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 3.75rem 1.5rem;
    border-radius: 0;
  }
`;
const Wrapper = styled.div`
  position: relative;
  max-width: 37rem;
  width: 100%;
  margin: 0 auto;
`;
const LinesImage = styled(Svg)`
  position: absolute;
  bottom: 0;
  left: 0;
`;
